import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import servicepageimg1 from "../Assets/images/ServicePageImg/02 OCEAN FREIGHT.jpg";
import servicepageimg2 from "../Assets/images/ServicePageImg/Air Freight Forwarding.png";
import servicepageimg3 from "../Assets/images/ServicePageImg/Land Freight.png";
import servicepageimg4 from "../Assets/images/ServicePageImg/04 WAREHOUSING.jpg";
import servicepageimg5 from "../Assets/images/ServicePageImg/Oil and Gas Importing & Exporting.png";
import servicepageimg6 from "../Assets/images/ServicePageImg/Project Cargo Handling.png";
import servicepageimg7 from "../Assets/images/ServicePageImg/Luxury Car Importing & Exporting.png";
import servicepageimg8 from "../Assets/images/ServicePageImg/Spare Parts & Manufacturing products.png";
import servicepageimg9 from "../Assets/images/ServicePageImg/Customs Clearance.png";
import bannerimg from "../Assets/images/Subbanner/02 Services.jpg";

// Reusable Service Section Component
const ServiceSection = ({
  id,
  title,
  image,
  description,
  points,
  imagePosition = "left",
}) => (
  <div className="container my-5">
    <section id={id}>
      <div
        className={`row align-items-center bg-light p-4 rounded shadow ${
          imagePosition === "right" ? "flex-row-reverse" : ""
        }`}
      >
        {/* Image Section */}
        <div className="col-md-6 text-center" data-aos="zoom-in-up">
          <img
            src={image}
            alt={title}
            className="img-fluid rounded shadow-sm"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        {/* Text Section */}
        <div className="col-md-6" data-aos="fade-right">
          <h2 className="text-primary mb-4">{title}</h2>
          <p className="text-muted" style={{ textAlign: "justify" }}>
            {description}
          </p>
          <ul className="list-unstyled">
            {points.map((point, index) => (
              <li key={index}>❖ {point}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  </div>
);

const Service = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div>
        <div style={{ position: "relative", marginTop: "160px" }}>
          <img
            src={bannerimg}
            alt="Contact Us Banner"
            className="w-100"
            style={{ maxHeight: "400px", objectFit: "cover" }}
          />
          <h1
            className="display-4"
            style={{
              position: "absolute",
              bottom: "20px",
              left: "20px",
              fontWeight: "bold",
              fontSize: "2rem",
              color: "white",
            }}
          >
            OUR SERVICES
          </h1>
        </div>
        {/* Ocean Freight Section */}
        <ServiceSection
          id="oceanfreight"
          title="Sea Freight Forwarding"
          image={servicepageimg1}
          description="Our credible working relations with shipping lines reflect that we
          can provide our clients with access to a global carrier portfolio,
          spanning major ports, with the best transit times, fully flexible
          scheduling and space protection agreements to meet your individual
          shipping requirements. With our broad range of ocean freight
          solutions covering varied equipment types and consolidation
          services we ensure your cargo reaches the right place, at the
          right time and in a cost-efficient manner."
          points={[
            "Sea Freight Exports/Imports",
            "Transit Shipments",
            "Sea to Air Shipments / Sea to Land shipments",
            "Sea Freight Customs Clearance in Dubai and other Emirates",
            "Warehousing and Storage Facility",
            "Container Management",
          ]}
        />

        {/* Air Freight Section */}
        <ServiceSection
          id="airfreight"
          title="Air Freight Forwarding"
          image={servicepageimg2}
          description="Offers international and domestic air freight forwarding services connecting all major global markets. As a major player in global air freight forwarding, Emarsale meets all your global transportation needs within United Arab Emirates and around the world. We handle shipments from small to large consignments with integrity and professionalism. Our team of experts also ensures that clients get the best by furnishing bespoke services that are time-definite and cost-effective."
          points={[
            "Air Freight Exports/Imports",
            "Transit Shipments-Transit ROW-ROW Shipments",
            "Warehousing and Storage Facility",
            "Air to Air Shipments",
            "Air to Sea Shipments",
            "Air to Land Shipments",
            "Air Freight Customs Clearance (All Over UAE)",
            "Handling Dangerous Goods Shipments (IATA-DGR)",
            "Handling Live Animals Shipments (IATA-AVI)",
            "Handling Valuable Shipments",
            "Air Freight Cross Trade Shipments",
          ]}
          imagePosition="right"
        />

        {/* Land Freight Section */}
        <ServiceSection
          id="landfreight"
          title="Land Freight Forwarding"
          image={servicepageimg3}
          description="Emarsale Global Shipping L.L.C years of Freight experience in offering its
          clients highest Level of Land Transportation Service to: Local Transportation within the UAE, GCC Transportation – Oman, Bahrain, Kuwait & Saudi Arabia, Upper Gulf – Jordan, Lebanon, Egypt, Iran, Iraq. With complete coordination of all Border Crossings, our clients are updated on time for the truck movements of their shipments and timely delivery arrangements with the help of dedicated staff. We offer Transport solutions for:"
          points={[
            "Door to Door Deliveries.",
            "LTL to Major Cities.",
            "Movements on Hydraulic Axles.",
            "Movement of Standard Containers.",
            "Temperature Controlled Shipments on Reefer Trucks.",
            "Movements of Smaller Cargo Via 3 ton, 7 ton and 10 ton.",
            "Flat bed and Box Trailers (40’ 50’ and 60’ Long Trailers).",
            "Assistance in obtaining road permissions and escort movements.",
            "Handling of Out of Gauge Cargo (OOG) Locally and Internationally Via Wide Load, Low Beds vehicles.",
          ]}
        />

        {/* Warehousing & Distribution Section */}
        <ServiceSection
          id="warehousing"
          title="Warehousing & Distribution"
          image={servicepageimg4}
          description="Emarsale provides a comprehensive package, including warehousing
          and distribution, managed by our dedicated team to meet the most
          stringent customer service requirements. With modern operational
          methods and advanced technology, we ensure supply chain excellence
          while achieving synergies across various business units. We offer last-mile delivery solutions with end-to-end logistics services. Our state-of-the-art warehouses and impeccable distribution network guarantee the efficient handling of both inbound and outbound goods."
          points={[
            "Storage and logistics solutions",
            "Supply chain and distribution management",
            "Inventory management",
            "Cross-docking services",
            "Transfer of ownership",
            "Customs clearance and transportation",
          ]}
          imagePosition="right"
        />
        {/* Oil and Gas Importing & Exporting */}
        <ServiceSection
          id="oil"
          title="Oil and Gas Importing & Exporting"
          image={servicepageimg5}
          description="Emarsale Global Shipping is a leading global company involved in shipping of oil and gas import and export. The
           company provides international oil and gas transportation services smoothly without any hassle.:"
          points={[
            "Crude Oil",
            "Base Oil",
            "Lubricant Oil",
            "Diesel",
            "Petrol",
            "Chemicals,"
          ]}
        />
        {/* Project Cargo Handling */}
         <ServiceSection
          id="cargo"
          title="Project Cargo Handling"
          image={servicepageimg6}
          description="We are Experts in Project Management
          Emarsale Global Shipping is a comprehensive logistics provider, offering end-to-end solutions. We are experts in
          project management and have successfully completed numerous projects in the GCC region. Our specialized services
          in handling project cargo and heavy lifts are tailored to meet the demands of the thriving construction, energy, and
          oil and gas sectors in the MENA region.."
          points={[
            
          ]}
          imagePosition="right"
        />
        {/* Luxury Car Importing & Exporting */}
        <ServiceSection
          id="luxury"
          title="Luxury Car Importing & Exporting"
          image={servicepageimg7}
          description="When it comes to car shipping to and from Dubai, we’ve got you covered. We ship cars overseas by air, sea container
car transport ships. For cars being shipped with personal effects, shipping by sea container is the most appropriate
method. Specialist and high value cars can be sent by airfreight.
Dependent on your destination we can arrange car shipments via the following ports: Jebel Ali, Abu Dhabi, Sharjah,
Ajman, & Hamriya. We can collect your vehicle from any UAE address or you can save by delivering the car yourself
to the nominated port of shipment.
We will take care of all the shipping documentation and the necessary export customs formalities when it comes to
car shipping Dubai. Once your car has departed on the vessel you will be sent the shipping documents and Bill of
Lading also advising you of the expected arrival date at the port of importation. On arrival the vehicle will need to be
cleared through customs and you must pay the clearing agent for the local duties, taxes and any other charges from
the shipping line related to vehicle handling."
          points={[
            
          ]}
        />
         {/* Automotive Spare Parts / Manufacturing products Importing & Exporting */}
        <ServiceSection
          id="SpareParts"
          title="  Spare Parts & Manufacturing products"
          image={servicepageimg8}
          description="Our team is capable of handling every aspect of your move. This means we can provide a professional quote for
moving your residence, goods export from the UAE or personal effects export/import. We handle everything from
Packing and loading: Our moving house Packers in UAE are absolute experts. We can package your goods securely
enough to avoid damage. Protecting your valuable assets is what we do and we have access to a number of excellent
packing resources and transportation tools to make sure that your items arrive safely. For personal effects export our
packing and loading services can help you to protect even the most valuable items in your home/office.

Forwarding: we can forward your goods as professional international movers in Dubai. Along with shipping services
from Dubai and moving services we have established an international network for forwarding freight shipments
worldwide. We can help you get access to just the type of space you need.
"
          points={[
            "General Goods",
            "Automotive Spare parts",
            "IT & Telecom",
            "Electronics",
            "Cosmetics & Luxury Goods",
            "Retail and Fashion",
            "eCommerce",
            "Industrial & Manufacturing",
            " Events And Exhibition Logistics"
          ]}
          imagePosition="right"
        />
        {/* Customs Clearance Service */}
        <ServiceSection
          id="CustomsClearance"
          title="Customs Clearance Service
"
          image={servicepageimg9}
          description="Customs clearance is an essential process for all the goods entering and leaving the country. It requires various import
and export document submissions, payment of duties, any additional cost like storage or testing, and VAT.
Customs clearance is completed in a few minutes or hours, but it can take days or weeks if there is missing
documentation or products require testing. In that case, the goods will stay on hold until all necessary documentation
is provided or agents check the goods"
          points={[
            
          ]}
        />
      </div>
    </>
  );
};

export default Service;
