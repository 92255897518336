import React, { useState } from "react";
import bannerimgo5 from "../Assets/images/Subbanner/05 Online Inquiry.jpg"
import "./OnlineInqury.css";
import {
  Tab,
  Tabs,
  Row,
  Col,
  InputGroup,
  Button,
  Container,
  Form,
  Alert,
} from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { sendEmarsaleEmail } from "../api/allapi";


const OnlineInqury = () => {
  const [key, setKey] = useState("fcl");
  const [formData, setFormData] = useState({
    origin: '',
    destination: '',
    commodity: '',
    pieces: '',
    dimensions: '',
    weight: '',
    cargoReadyDate: '',
    incoterm: '',
    cargoType: 'Non Hazardous',
    customsClearance: false,
    marineInsurance: false,
    company: '',
    contactName: '',
    email: '',
    phone: '',
    message: '',
    subject: '',
    containerType: '',
    Qty: '',
    stuffingType: 'Dock Stuffing',
    Packages:'',

  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");

  //handle input changes
  const handlechange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.contactName) newErrors.contactName = "Contact Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.origin) newErrors.origin = "Origin is required";
    if (!formData.destination) newErrors.destination = "Destination is required";
    if (!formData.containerType) newErrors.containerType = "Container Type is required";
    if (!formData.Qty) newErrors.Qty = "Quantity is required";
    return newErrors;
  };

//handle form submission 
 const handleSubmit = async (e) => {

  
  e.preventDefault();
  setErrors({});
  setLoading(true);
  setSuccess("");
  const formErrors = validateForm();
 
  

  if (Object.keys(formErrors).length > 0) {
    setErrors(formErrors);
    setLoading(false);
    return;
 }

 try {
 
  await sendEmarsaleEmail(formData);
  setSuccess("Email sent successfully");
  setFormData({
         origin: '',
        destination: '',
        commodity: '',
        pieces: '',
        dimensions: '',
        weight: '',
        cargoReadyDate: '',
        incoterm: '',
        cargoType: 'Non Hazardous',
        customsClearance: false,
        marineInsurance: false,
        company: '',
        contactName: '',
        email: '',
        phone: '',
        message: '',
        subject: '',
        containerType: '',
        Qty: '',
        stuffingType: 'Dock Stuffing',
        Packages: '',
        enquiryType: key,
  });
  
 } catch (error) {

   setErrors({
    general: error.response?.data?.message || "Failed to send email",
   });
 }finally{
  setLoading(false)
 }
 };


  return (
    
    <div>  
           <div style={{ position: "relative", marginTop: "160px" }}>
        <img
          src={bannerimgo5}
          alt="Contact Us Banner"
          className="w-100"
          style={{ maxHeight: "400px", objectFit: "cover" }}
        />
        <h1
          className="display-4"
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
          }}
        >
          ONLINE ENQUIRY
        </h1>
      </div>
      <Container className="text-center mt-5">
      {/* Center the buttons */}
      <Row className="justify-content-center mb-4">
        <Col md="auto">
          <InputGroup>
            <Button
              variant={key === "fcl" ? "primary" : "outline-primary"}
              className="mx-1 shadow-sm"
              onClick={() => setKey("fcl")}
            >
              <i className="bi bi-box"></i> FCL
            </Button>
            <Button
              variant={key === "lcl" ? "primary" : "outline-primary"}
              className="mx-1 shadow-sm"
              onClick={() => setKey("lcl")}
            >
              <i className="bi bi-box"></i> LCL
            </Button>
            <Button
              variant={key === "air" ? "primary" : "outline-primary"}
              className="mx-1 mshadow-s"
              onClick={() => setKey("air")}
            >
              <i className="bi bi-airplane"></i> AIR
            </Button>
          </InputGroup>
        </Col>
      </Row>

      {/* Tabs for content */}
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="text-start mb-4"
      >
        {/* FCL Tab */}
        <Tab eventKey="fcl" title="">
          <div className="p-4 bg-light rounded shadow-sm text-start">
            <h4 className="mb-4 text-center">FCL Inquiry</h4>
            <Form >
              
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="origin">
                    <Form.Label>Origin*</Form.Label>
                    <Form.Control type="text"
                     placeholder="Origin Port..."
                      name="origin"
                    value={formData.origin}
                    onChange={handlechange}
                    isInvalid={!!errors.origin} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="destination">
                    <Form.Label>Destination*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Destination Port..."
                      name="destination"
                      value={formData.destination}
                      onChange={handlechange}
                      isInvalid={!!errors.destination}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="containerType">
                    <Form.Label>Container Type*</Form.Label>
                    <Form.Select 
                    value={formData.cargoType} 
                    onChange={handlechange}
                    name="containerType"
                    isInvalid={!!errors.cargoType}>
                      <option value="" disabled>Select container type</option>
                      <option value="20 ft">20 ft</option>
                      <option value="40 ft">40 ft</option>
                      <option value="Reefer">Reefer</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="quantity">
                    <Form.Label>Qty*</Form.Label>
                    <Form.Control type="number" 
                    value={formData.Qty}
                    onChange={handlechange}
                    name="Qty"
                    isInvalid={!!errors.Qty} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="commodity">
                    <Form.Label>Commodity*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E.g. Rice, Spices..."
                      value={formData.commodity}
                      onChange={handlechange}
                      name="commodity"
                      isInvalid={!!errors.commodity}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoReadyDate">
                    <Form.Label>Cargo Ready Date*</Form.Label>
                    <Form.Select 
                    name="cargoReadyDate"
                    value={formData.cargoReadyDate}
                    onChange={handlechange}>
                      <option>Select time frame</option>
                      <option value="Immediate">Immediate</option>
                      <option value="1-2 weeks">1-2 weeks</option>
                      <option value="3-4 weeks">3-4 weeks</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="incoterm">
                    <Form.Label>Incoterm*</Form.Label>
                    <Form.Select 
                    name="incoterm"
                    value={formData.incoterm}
                    onChange={handlechange}>
                      <option value="CFR: Cost And Freight">CFR: Cost And Freight</option>
                      <option value="FOB: Free On Board">FOB: Free On Board</option>
                      <option value="CIF: Cost, Insurance & Freight">CIF: Cost, Insurance & Freight</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoType">
                    <Form.Label>Cargo Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Non Hazardous"
                        name="cargoType"
                        defaultChecked
                        className="me-3"
                        value={formData.cargoType}
                        onChange={handlechange}
                      />
                      <Form.Check
                        type="radio"
                        label="Hazardous"
                        name="cargoType"
                        value={formData.cargoType}
                        onChange={handlechange}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="stuffingType">
                    <Form.Label>Stuffing Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Dock Stuffing"
                        name="stuffingType"
                        defaultChecked
                        className="me-3"
                        value={formData.stuffingType}
                        onChange={handleSubmit}
                      />
                      <Form.Check
                        type="radio"
                        label="Factory Stuffing"
                        name="stuffingType"
                        value={formData.stuffingType}
                        onChange={handleSubmit}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Customs Clearance" />
                </Col>
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Marine Insurance" />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="company">
                    <Form.Label>Company*</Form.Label>
                    <Form.Control type="text" placeholder="Your company name" 
                    name="company"
                    value={formData.company}
                    onChange={handlechange}
                    isInvalid={!!errors.company}/>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contactName">
                    <Form.Label>Contact Name*</Form.Label>
                    <Form.Control type="text" placeholder="Your name"
                    name="contactName"
                    value={formData.contactName} 
                    onChange={handlechange}
                    isInvalid={!!errors.fullname}/>
                    
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Your business email address"
                      name="email"
                      value={formData.email}
                      onChange={handlechange}
                      isInvalid={!!errors.fullname}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Your phone number"
                      name="phone"
                      value={formData.phone}
                      onChange={handlechange}
                      isInvalid={!!errors.phone}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {success && (
                <Alert variant="success" >{success}</Alert>
              )}
              {errors.general && (
                <Alert variant="danger">{errors.general}</Alert>
              )}
              <div className="text-center mt-4">
                <Button onClick={handleSubmit} variant="primary" type="submit" className="px-5 py-2" disabled={loading}>
                  {loading ? "Sending..." : "submit"}        
                </Button>
              </div>
              <div className="text-center mt-3 text-muted">
                * All fields are mandatory
              </div>
            </Form>
          </div>
        </Tab>

        {/* LCL Tab */}
        <Tab eventKey="lcl" title="">
          <div className="p-4 bg-light rounded shadow-sm text-start">
            <h4 className="mb-4 text-center">LCL Inquiry</h4>
            <Form >
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="origin">
                    <Form.Label>Origin*</Form.Label>
                    <Form.Control type="text" placeholder="Origin Port..."
                    // name="origin"
                    // value={formData.origin} 
                    // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="destination">
                    <Form.Label>Destination*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Destination Port..."
                      // name="destination"
                      // value={formData.destination}
                      // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="commodity">
                    <Form.Label>Commodity*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E.g. Mangoes, Pulses..."
                      name="commodity"
                      // value={formData.commodity}
                      // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="packages">
                    <Form.Label>No. Of Packages*</Form.Label>
                    <Form.Control type="number" 
                  // name="packages"
                  // value={formData.Packages}
                  // onChange={handlechange}
                  />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Dimensions Per Package*</Form.Label>
                  <InputGroup>
                    <Form.Control type="number" placeholder="L"  />
                    <Form.Control type="number" placeholder="W" />
                    <Form.Control type="number" placeholder="H" />
                  </InputGroup>
                  <Form.Text className="text-muted">In centimeters</Form.Text>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="weight">
                    <Form.Label>Weight Per Package*</Form.Label>
                    <Form.Control type="number" placeholder="Kgs" 
                    // name="weight"
                    // value={formData.weight}
                    // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoReadyDate">
                    <Form.Label>Cargo Ready Date*</Form.Label>
                    <Form.Select 
                    // name="cargoReadyDate"
                    // value={formData.cargoReadyDate}
                    // onChange={handlechange}
                    >
                      <option value="">Select time frame</option>
                      <option value="Immediate">Immediate</option>
                      <option value="1-2 weeks">1-2 weeks</option>
                      <option value="3-4 weeks">3-4 weeks</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="incoterm">
                    <Form.Label>Incoterm*</Form.Label>
                    <Form.Select
                    // name="incoterm"
                    // value={formData.incoterm}
                    // onChange={handlechange}
                    >
                      <option value="">CFR: Cost And Freight</option>
                      <option value="FOB: Free On Board"></option>
                      <option value="CIF: Cost, Insurance & Freight">CIF: Cost, Insurance & Freight</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoType">
                    <Form.Label>Cargo Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Non Hazardous"
                        name="cargoType"               
                        className="me-3"
                        // value={formData.cargoType}
                        // onChange={handlechange}
                      />
                      <Form.Check
                        type="radio"
                        label="Hazardous"
                        name="cargoType"
                        // value={formData.cargoType}
                        // onChange={handlechange}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Customs Clearance" 
                  name="customsClearance"
                  // value={formData.customsClearance} 
                  // onChange={handlechange}
                  />
                </Col>
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Marine Insurance" 
                  name="marineInsurance"
                  // value={formData.customsClearance} 
                  // onChange={handlechange}
                  />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="company">
                    <Form.Label>Company*</Form.Label>
                    <Form.Control type="text" placeholder="Your company name"
                    name="company"
                    // value={formData.company}
                    // onChange={handlechange} 
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contactName">
                    <Form.Label>Contact Name*</Form.Label>
                    <Form.Control type="text" placeholder="Your name"
                    name="contactName" 
                    // onChange={handlechange}
                    // value={formData.contactName}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Your business email address"
                      name="email"
                      // value={formData.email}
                      // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Your phone number"
                      name="phone"
                      // value={formData.phone}
                      // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {success && (
                <Alert variant="success" >{success}</Alert>
              )}
              {errors.general && (
                <Alert variant="danger">{errors.general}</Alert>
              )}
              <div className="text-center mt-4">
              <Button  variant="primary" type="submit" className="px-5 py-2" disabled={loading}>
                  {loading ? "Sending..." : "submit"}        
                </Button>
              </div>
              <div className="text-center mt-3 text-muted">
                * All fields are mandatory
              </div>
            </Form>
          </div>
        </Tab>

        {/* AIR Tab */}
        <Tab eventKey="air" title="">
          <div className="p-4 bg-light rounded shadow-sm text-start">
            <h4 className="mb-4 text-center">AIR Inquiry</h4>
            <Form>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="origin">
                    <Form.Label>Origin*</Form.Label>
                    <Form.Control type="text" placeholder="Origin Airport..." 
                    name="origin"
                    // value={formData.origin}
                    // onChange={handlechange}
                    />
                    
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="destination">
                    <Form.Label>Destination*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Destination Airport..."
                      // name="destination"
                      // value={formData.destination}
                      // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="commodity">
                    <Form.Label>Commodity*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="E.g. Electronics, Garments..."
                      // name="commodity"
                      // value={formData.commodity}
                      // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="pieces">
                    <Form.Label>No. Of Pieces*</Form.Label>
                    <Form.Control type="number" 
                    // name="pieces"
                    // value={formData.pieces}
                    // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Dimensions Per Piece*</Form.Label>
                  <InputGroup>
                    <Form.Control type="number" placeholder="L" />
                    <Form.Control type="number" placeholder="W" />
                    <Form.Control type="number" placeholder="H" />
                  </InputGroup>
                  <Form.Text className="text-muted">In centimeters</Form.Text>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="weight">
                    <Form.Label>Weight Per Piece*</Form.Label>
                    <Form.Control type="number" placeholder="Kgs" 
                    name="weight"
                    // value={formData.weight}
                    // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoReadyDate">
                    <Form.Label>Cargo Ready Date*</Form.Label>
                    <Form.Select>
                      <option>Select time frame</option>
                      <option>Immediate</option>
                      <option>1-2 weeks</option>
                      <option>3-4 weeks</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="incoterm">
                    <Form.Label>Incoterm*</Form.Label>
                    <Form.Select>
                      <option>CFR: Cost And Freight</option>
                      <option>FOB: Free On Board</option>
                      <option>CIF: Cost, Insurance & Freight</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="cargoType">
                    <Form.Label>Cargo Type*</Form.Label>
                    <div className="d-flex">
                      <Form.Check
                        type="radio"
                        label="Non Hazardous"
                        name="cargoType"
                        defaultChecked
                        className="me-3"
                      />
                      <Form.Check
                        type="radio"
                        label="Hazardous"
                        name="cargoType"
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Customs Clearance" />
                </Col>
                <Col md={6}>
                  <Form.Check type="checkbox" label="Need Marine Insurance" />
                </Col>
              </Row>

              <hr />

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="company">
                    <Form.Label>Company*</Form.Label>
                    <Form.Control type="text" placeholder="Your company name" 
                    // name="company"
                    // value={formData.company}
                    // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="contactName">
                    <Form.Label>Contact Name*</Form.Label>
                    <Form.Control type="text" placeholder="Your name"
                    // name="contactName"
                    // value={formData.contactName}
                    // onChange={handlechange}
                     />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Your business email address"
                      // name="email"
                      // value={formData.email}
                      // onChange={handlechange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Your phone number"
                      // name="phone"
                      // value={formData.phone}
                      // onChange={handlechange}

                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="text-center mt-4">

                <Button variant="primary" type="submit" className="px-5 py-2">
                  Request Quote
                </Button>
              </div>
              <div className="text-center mt-3 text-muted">
                * All fields are mandatory
              </div>
            </Form>
          </div>
        </Tab>
      </Tabs>
    </Container>
    </div>
  );
};

export default OnlineInqury;
