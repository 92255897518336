import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import bannerimg4 from "../Assets/images/BannerImage/Gallery.png";

// Import local images from the src folder
import image1 from '../Assets/images/Gallery/01 Shipp.jpg';
import image2 from '../Assets/images/Gallery/02 Shipp.jpg';
import image3 from '../Assets/images/Gallery/03 Shipp.jpg';
import image4 from '../Assets/images/Gallery/04 Shipp.jpeg';

const images = [image1, image2, image3 ,image4];

const Gallery = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openLightbox = (image) => {
    setCurrentImage(image);
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setCurrentImage('');
  };

  return (
    <div>
      {/* Banner Image */}
      <div style={{ position: "relative", marginTop: "160px" }}>
        <img
          src={bannerimg4}
          alt="Contact Us Banner"
          className="w-100"
          style={{ maxHeight: "400px", objectFit: "cover" }}
        />
        <h1
          className="display-4"
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
          }}
        >
          OUR GALLERY
        </h1>
      </div>

      {/* Gallery Grid */}
      <div style={{ marginTop: '80px' }}>
        <Container>
          <Row>
            {images.map((src, index) => (
              <Col md={3} sm={6} xs={12} key={index} className="mb-4">
                <Card
                  className="image-card"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => openLightbox(src)}
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{ overflow: 'hidden', height: '250px', width: '100%' }}>
                    <Card.Img
                      variant="top"
                      src={src}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover', // Ensures the image covers the entire container
                        transition: 'transform 0.5s ease',
                        transform: hoveredIndex === index ? 'scale(1.1)' : 'scale(1)',
                      }}
                    />
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Lightbox Modal */}
        <Modal show={isLightboxOpen} onHide={closeLightbox} centered size="lg">
        <Modal.Body className="p-0">
            <img src={currentImage} alt="Lightbox" style={{ width: '100%', height: 'auto' }} />
          </Modal.Body>
          <Button
            variant="light"
            className="position-absolute top-0 end-0 m-2"
            onClick={closeLightbox}
          >
            ×
          </Button>
        </Modal>
      </div>
    </div>
  );
};

export default Gallery;
