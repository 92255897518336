import axios from 'axios';

const BASE_URL = 'https://xeniamailsender.onrender.com'; 

export const sendEmarsaleEmail = async (emailData, file) => {
  const formData = new FormData();

  // Append email data to FormData
  for (const key in emailData) {
    formData.append(key, emailData[key]);
  }

  // Append the file if it exists
  if (file) {
    formData.append('file', file);
  }

  try {
    const response = await axios.post(`${BASE_URL}/emarsale/send-email`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data; 
  } catch (error) {
    let errorMessage;

    
    if (error.response) {
      
      const status = error.response.status;
      if (status >= 400 && status < 500) {
        errorMessage = 'It seems there was an issue with your submission. Please check your input and try again.';
      } else if (status >= 500) {
        errorMessage = 'Oops! Something went wrong on our end. Please try again later.';
      } else {
        errorMessage = 'An unexpected error occurred. Please try again.';
      }
    } else if (error.request) {
      
      errorMessage = 'No response received from the server. Please check your internet connection and try again.';
    } else {
      
      errorMessage = 'An error occurred while setting up the request. Please try again.';
    }

    console.error('Error details:', error); // Log the actual error for debugging
    throw new Error(errorMessage); // Throw the user-friendly error message
  }
};
