import React from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import Profile from "./Profile";
import aboutimg from "../Assets/images/Subbanner/01 About Us.jpg"
import aboutimg2 from "../Assets/images/About/About Us 1st.png"


AOS.init();

const About = () => {
  return (
    <div>
    
      {/* Banner Section */}
      <div>
      {/* Banner Section */}
      <div style={{ position: "relative", marginTop: "160px" }}>
        <img
          src={aboutimg}
          alt="Contact Us Banner"
          className="w-100"
          style={{ maxHeight: "400px", objectFit: "cover" }}
        />
        <h1
          className="display-4"
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            fontWeight: "bold",
            fontSize: "2rem",
            color: "white",
          }}
        >
           ABOUT US
        </h1>
      </div>

      {/* Other content... */}
    </div>


      {/* About Us Section */}
      <section id="about" className="container my-5">
        <div className="row align-items-center bg-light p-4 rounded shadow">
          <div className="col-md-6" data-aos="zoom-in">
            <h1 className="text-primary mb-4">About Us</h1>
            <p className="text-muted" style={{ textAlign: "justify" }}>
              Welcome to Emarsale Global Shipping L.L.C, where logistics meet innovation.
              As a freight forwarding and logistics company, we specialize in
              providing seamless and reliable solutions across the globe. We support
              trade and industry with global movements of goods through supply chain
              management, warehousing, contract logistics, and transportation. We
              connect businesses with global trade, offering services, solutions,
              and innovative ideas to meet the complex needs of various business
              entities worldwide.
              <br />
              <br />
              Combining cutting-edge technology with industry expertise, we ensure
              that your cargo is delivered on time.
            </p>
          </div>
          <div className="col-md-6 text-center" data-aos="fade-up">
            <img
              src={aboutimg2}
              alt="Global Logistics"
              className="img-fluid rounded shadow-sm"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
        </div>
      </section>

      <Profile />
    </div>
  );
};

export default About;
